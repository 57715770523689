<template>
  <dash-layout>
    <template slot="dash-title">Account Settings</template>
    <template slot="dash-body">
      <div>
        <v-dialog v-model="passwordChangeDialog" max-width="500" persistent>
          <v-card>
            <v-card-title class="headline">Update Password</v-card-title>
            <v-container grid-list-xl fluid  class="pa-12">
              <v-layout row wrap>
                
                <v-flex xs12 sm12 md12>
                  <v-text-field
                          label="Current Password"
                          v-model="current_password"
                          :append-icon="showCurrentPassword ? 'visibility' : 'visibility_off'"
                          :rules="[rules.required, rules.minLength]"
                          :type="showCurrentPassword ? 'text' : 'password'"
                          name="input-10-1"
                          hint="At least 8 characters"
                          counter
                          outlined
                          @click:append="showCurrentPassword = !showCurrentPassword"
                          validate-on-blur
                          v-on:keyup.enter="submit">
                  </v-text-field>
                  <v-text-field
                          label="New Password"
                          v-model="new_password"
                          :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
                          :rules="[rules.required, rules.minLength]"
                          :type="showNewPassword ? 'text' : 'password'"
                          name="input-10-1"
                          hint="At least 8 characters"
                          counter
                          outlined
                          @click:append="showNewPassword = !showNewPassword"
                          validate-on-blur
                          v-on:keyup.enter="submit">
                  </v-text-field>
                  <v-text-field
                          label="Confirm Password"
                          v-model="confirm_password"
                          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                          :rules="[rules.required, rules.minLength]"
                          :type="showConfirmPassword ? 'text' : 'password'"
                          name="input-10-1"
                          hint="At least 8 characters"
                          counter
                          outlined
                          @click:append="showConfirmPassword = !showConfirmPassword"
                          validate-on-blur
                          v-on:keyup.enter="submit">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" outlined @click="passwordChangeDialog = false"> Cancel</v-btn>
              <v-btn color="red darken-1" outlined :disabled="!canUpdate || passwordUpdating" text @click="UpdatePassword()">Update
                <v-progress-circular
                        v-if="passwordUpdating"
                        style="position: absolute; right: 30%;"
                        indeterminate
                        :size="22"
                        color="#fff"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="closeAccountDialog" max-width="500">
          <v-card>
            <v-card-title class="headline">Are you sure closing your account?</v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" outlined @click="closeAccountDialog = false"> Cancel</v-btn>
              <v-btn color="red darken-1" outlined :disabled="accountClosing" text @click="CloseAccount()">Confirm
                <v-progress-circular
                        v-if="accountClosing"
                        style="position: absolute; right: 30%;"
                        indeterminate
                        :size="22"
                        color="#fff"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div id="setTarget">

          <v-card  class="pa-10" >
            <v-container grid-list-xl fluid pt-0 pa-0 class="py-0">
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-row class="mb-6">
                    <v-col cols="12" xs="12" sm="12" md="6">
                      <v-card outlined class="pa-8" height="100%">
                        <v-card-title>User details</v-card-title>
                        <v-text-field
                          v-model="first_name"
                          label="First Name"
                          placeholder="John"
                          :rules="[rules.emptyAlphabet]"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="last_name"
                          label="Last Name"
                          placeholder="Doe"
                          :rules="[rules.emptyAlphabet]"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model="email"
                          label="Email"
                          :rules="[rules.required, rules.email]"
                          disabled
                          outlined
                        ></v-text-field>                        
                        <v-text-field
                          v-model="phone_number"
                          label="Phone"
                          v-mask="mask"
                          :rules="[rules.emptyPhone]"
                          outlined
                        ></v-text-field>

                        <v-card-actions class="d-flex justify-end">
                          <v-btn @click="save" :disabled="!canSave || saving" depressed class="ml-3 mt-3" style="width: 100%;">Update
                            <v-progress-circular
                                   v-if=" getUserUpdateLoading"
                                    style="position: absolute; right: 30%;"
                                    indeterminate
                                    :size="22"
                                    color="#fff"
                            ></v-progress-circular>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6">
                      <v-card outlined class="pa-8" height="100%">
                        <v-card-title>Danger Zone</v-card-title>
                        <v-card-actions>
                          <v-flex xs12 sm12 md12>
                            <v-row class="ma-12 justify-center">
                              <v-btn @click="passwordChangeDialog=true" outlined class="red darken-1">Change Password</v-btn>
                            </v-row>
                            <v-row class="ma-12 justify-center">
                              <v-btn @click="closeAccountDialog=true" outlined class="red darken-4">Close Account</v-btn>
                            </v-row>
                          </v-flex>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </div>
      </div>
    </template>
  </dash-layout>

</template>

<script>
  import DashLayout from '../../containers/Layout'
  import { mapGetters } from 'vuex'
  import moment from 'moment-timezone'

  const validators = {
    alphabet: /^[A-Za-z\s]+$/,
    email: /\S+@\S+\.\S+/,
    number: /^[\d]+$/,
    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  }

  export default {
    name: 'AccountSettings',
    computed: {
      ...mapGetters(['user', 'getUserUpdateLoading']),
      canSave: function(){
        return this.rules.emptyAlphabet(this.first_name) &&
          this.rules.emptyAlphabet(this.last_name) &&
          this.rules.emptyPhone(this.phone_number)
      },
      canUpdate: function(){
        return this.current_password && (this.current_password.length >= 8) &&
          this.new_password && (this.new_password.length >= 8) &&
          (this.new_password == this.confirm_password)
      }
    },
    mounted() {
      this.email = this.user.email
      this.role = this.user.role
      this.first_name = this.user.first_name
      this.last_name = this.user.last_name
      this.phone_number = this.user.phone_number
    },
    data() {
      return {
        mask: '(###) ###-####',
        first_name: '',
        last_name: '',
        email: '',
        role: 'User',
        phone_number: '',

        current_password: '',
        new_password: '',
        confirm_password: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        passwordUpdating: false,
        accountClosing: false,

        rules: {
          required: value => !!value || 'Required.',
          integer: value => Number.isInteger(Number(value)) || 'Invalid value.',
          onlyAlphabet: value => validators.alphabet.test(value) || 'Invalid value.',
          emptyAlphabet: value => {  if (value && value.length > 0) {  return validators.alphabet.test(value) ||  "Invalid value"; } else { return true }},
          onlyNumber: value => validators.number.test(value) || 'Invalid value.',
          emptyNumber: value => {  if (value && value.length > 0) {  return validators.number.test(value) ||  "Invalid value"; } else { return true }},
          email: value => validators.email.test(value) || 'Invalid email.',
          minLength: value => value.length >= 8 || 'Min 8 characters',
          phone: value => validators.phone.test(value) || 'Invalid phone.',
          emptyPhone: value => {  if (value && value.length > 0) {  return validators.phone.test(value) ||  "Phone must be valid"; } else { return true }}
        },
        saving: false,
        passwordChangeDialog: false,
        closeAccountDialog: false,
      }
    },
    methods: {
      async save() {
        this.saving = true
        let userData = this.user
        
        delete userData.access_token
        delete userData.refresh_token
        delete userData.expires_in
        delete userData.verified

        userData.first_name = this.first_name
        userData.last_name = this.last_name
        userData.email = this.email
        userData.role = this.role
        userData.phone_number = this.phone_number
        await this.$store.dispatch('updateProfile', userData)
      },
      async UpdatePassword() {
        this.passwordUpdating = true
        const payload = {
          email: this.email,
          current_password: this.current_password,
          new_password: this.new_password,
          confirm_password: this.confirm_password
        }

        await this.$store.dispatch('updatePassword', payload)
        this.passwordChangeDialog = false
        this.passwordUpdating = false
      },
      async CloseAccount() {
        this.accountClosing = true
        const ret = await this.$store.dispatch('closeAccount')
        if(ret){
          this.$store.dispatch('signOutUserFromApi');
          this.$router.push("/session/login")
        }
        this.closeAccountDialog = false
        this.accountClosing = false
      }
    },
    components: {
      DashLayout
    }
  }
</script>